<template lang="pug">
b-row
  b-col.mb-3(cols="12")
    .text-center
      qrcode.qr-canvas.mb-2(:value="qrCode", :options="{ width: 250 }")
      div(v-if="email && type == 'perfil'")
        b-button.mb-2(variant="success", @click="sendQR", :disabled="loadingData") 
          mail-icon.icon-sm.mr-2
          span Enviar QR por correo
        p.text-sm.w-75.mx-auto.text-primary Se enviará al correo asociado al perfil: {{ email }}

  b-col.mb-3(cols="12")
    h4
      b-badge.mb-3(:variant="active ? 'success' : 'warning'", pill) {{ active ? 'Activo' : 'Deshabilitado' }}
    b-form-row
      b-col
        p.mb-0 Estado de QR
        p.mb-0.text-secondary.text-sm Deshabilita temporalmente el acceso con este QR
      b-col(cols="auto")
        b-form-checkbox(switch, v-model="active")

  b-col.mb-3(cols="12", v-if="type == 'registro'")
    b-form-group(label="Nombre de QR")
      b-form-input(placeholder="Nombre de QR", v-model="qrName")

  b-col.mb-3(cols="12")
    p.font-weight-bold Vigencia de QR

    b-form-group(label="Fecha de vencimiento")
      b-form-datepicker(
        :min="minDate",
        locale="es",
        v-model="expirationDate",
        placeholder="Seleccionar fecha",
        reset-button,
        label-reset-button="Reiniciar"
      )
      span.text-warning.text-sm(v-if="!expirationDate") No tiene fecha de vencimiento
      .text-right.mt-2(v-if="expirationDate")
        b-button(
          size="sm",
          variant="outline-danger",
          @click="expirationDate = null"
        ) Quitar fecha

    b-form-group(label="Restricción por horarios")
      b-form-row
        b-col(cols="6")
          b-form-group(descripción="De las")
            b-form-timepicker(
              v-model="timeFrom",
              locale="es",
              placeholder="De las",
              :hour12="false",
              minutes-step="5",
              :state="timeTo && !timeFrom ? false : null"
            )
        b-col(cols="6")
          b-form-group(descripción="A las")
            b-form-timepicker(
              v-model="timeTo",
              locale="es",
              placeholder="A las",
              :hour12="false",
              minutes-step="5",
              :state="timeFrom && !timeTo ? false : null"
            )
      .text-right.mt-2(v-if="timeFrom || timeTo")
        b-button(
          size="sm",
          variant="outline-danger",
          @click="timeFrom = null; timeTo = null"
        ) Quitar horarios

    b-form-group(
      label="Días de la semana",
      description="Selecciona los días de la semana que puede acceder"
    )
      b-form-checkbox-group(
        stacked,
        :options="daysOfWeek",
        v-model="restrictionDays"
      )

  b-col(cols="12")
    b-button(block, variant="primary", @click="updateQR") {{ type == 'registro' ? `Guardar cambios de \"${qrId}. ${qrName || 'Sin nombre'}\"` : 'Guardar cambios de QR' }}
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const perfilController = createNamespacedHelpers("perfil");
const clienteController = createNamespacedHelpers("cliente");

export default {
  props: {
    qrCode: {
      type: String,
      default: "",
      required: true,
    },
    email: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'registro'
    }
  },
  data() {
    return {
      minDate: new Date(),
      expirationDate: null,
      restrictionDays: [0, 1, 2, 3, 4, 5, 6],
      daysOfWeek: [
        {
          value: 0,
          text: "Domingo",
        },
        {
          value: 1,
          text: "Lunes",
        },
        {
          value: 2,
          text: "Martes",
        },
        {
          value: 3,
          text: "Miércoles",
        },
        {
          value: 4,
          text: "Jueves",
        },
        {
          value: 5,
          text: "Viernes",
        },
        {
          value: 6,
          text: "Sábado",
        },
      ],
      timeFrom: null,
      timeTo: null,
      qrId: null,
      qrName: '',
      active: false
    };
  },
  methods: {
    ...mapActions([
      "qr/scan",
      "qr/update",
      "perfil/sendQR",
    ]),
    scanQR() {
      this["qr/scan"]({
        qr_code: this.qrCode,
        callback: (res) => {
          if (res.success) {
            let validity = res.resource.validity;
            this.qrId = res.resource.qr_id;
            this.qrName = res.resource.name;
            this.active = res.resource.active;

            if (validity) {
              if (validity.expire_at) {
                this.expirationDate = this.$moment(validity.expire_at).format(
                  "YYYY-MM-DD"
                );
              }

              if (validity.days_of_week) {
                this.restrictionDays = validity.days_of_week;
              }

              if (validity.from) {
                this.timeFrom =
                  validity.from.hour + ":" + validity.from.minute + ":" + "00";
              }

              if (validity.to) {
                this.timeTo =
                  validity.to.hour + ":" + validity.to.minute + ":" + "00";
              }
            }
          } else {
            this.$bvToast.toast(
              res.error || "Ocurrió un error obteniendo validez de QR.",
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    updateQR() {
      let validity = {
        expire_at: this.expirationDate
          ? this.$moment(this.expirationDate, "YYYY-MM-DD")
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString()
          : null,
        days_of_week: this.restrictionDays,
      };

      if (this.timeFrom && this.timeTo) {
        validity.from = {
          hour: this.timeFrom.split(":")[0],
          minute: this.timeFrom.split(":")[1],
        };

        validity.to = {
          hour: this.timeTo.split(":")[0],
          minute: this.timeTo.split(":")[1],
        };
      }

      this["qr/update"]({
        qr_id: this.qrId,
        validity,
        name: this.qrName,
        active: this.active,
        callback: (res) => {
          if (res.success) {
            this.$store.commit('qr/updateQRInList', res.resource)

            this.$bvToast.toast(
              "Se actualizó la validez del QR correctamente.",
              {
                title: "Validez actualizada",
                variant: "success",
              }
            );
          } else {
            this.$bvToast.toast(
              res.error || "Ocurrió un error actualizando validez de QR.",
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    sendQR() {
      this.$bvModal
        .msgBoxConfirm(
          `Se enviará el QR al correo ${this.email}. ¿Deseas continuar?`,
          {
            okVariant: 'success',
            okTitle: 'Enviar'
          }
        )
        .then((value) => {
          if (value == true) {
            this["perfil/sendQR"]({
              perfil_id: this.$route.params.perfil_id,
              callback: (res) => {
                if (res.success) {
                  this.$bvToast.toast(
                    `Se envío el QR al correo ${this.email}.`,
                    {
                      title: "Enviado",
                      variant: "success",
                    }
                  );
                } else {
                  this.$bvToast.toast(
                    `Ocurrió un error enviando QR. Por favor intenta de nuevo.`,
                    {
                      title: "Ocurrió un error",
                      variant: "danger",
                    }
                  );
                }
              },
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  mounted () {
    if (this.qrCode)
      this.scanQR()
  }
};
</script>

<style lang="scss" scoped>
</style>