<template lang="pug">
b-container(fluid)
  h3.mb-3 Perfil
    b-spinner.ml-3.mb-1(variant="primary", small, v-if="loadingData")
  div
    validation-observer(v-slot="{invalid, handleSubmit}")
      b-form(@submit.prevent="handleSubmit(save)")
        b-row.mb-5
          b-col.mb-4(cols="6")
            b-row
              b-col.mb-3(cols="12")
                b-form-group(label="Nombre")
                  validation-provider(
                    name="nombre",
                    rules="required|min:1",
                    v-slot="{ errors }"
                  )
                    b-form-input(
                      placeholder="Nombre",
                      required,
                      name="name",
                      v-model="profile.first_name",
                      :state="errors[0] ? false : null"
                    )
                    b-form-invalid-feedback {{ errors[0] }}
              b-col.mb-3(cols="12")
                b-form-group(label="Apellido")
                  validation-provider(
                    name="apellido",
                    rules="required|min:1",
                    v-slot="{ errors }"
                  )
                    b-form-input(
                      placeholder="Apellido",
                      required,
                      name="last_name",
                      v-model="profile.last_name",
                      :state="errors[0] ? false : null"
                    )
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="12")
                b-form-group(label="Email")
                  validation-provider(
                    name="email",
                    rules="required|email",
                    v-slot="{ errors }"
                  )
                    b-form-input(
                      placeholder="Email",
                      required,
                      name="email",
                      v-model="profile.email",
                      :state="errors[0] ? false : null"
                    )
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="12")
                b-form-group(label="Teléfono")
                  validation-provider(
                    name="teléfono",
                    rules="required|digits:10",
                    v-slot="{ errors }"
                  )
                    b-form-input(
                      placeholder="Teléfono",
                      required,
                      name="phone",
                      v-model="profile.phone_number",
                      :state="errors[0] ? false : null"
                    )
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="12")
                b-form-group(label="Cliente")
                  validation-provider(
                    name="cliente",
                    rules="required",
                    v-slot="{ errors }"
                  )
                    b-form-select(
                      required,
                      name="cliente",
                      :options="parsedClientes",
                      v-model="profile.cliente_id",
                      :state="errors[0] ? false : null",
                      @change="profile.casa_id = null; getCliente()"
                    )
                      template(#first)
                        b-form-select-option(disabled, :value="null") -- Seleccionar cliente --
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="12")
                b-form-group(label="Casa que visita")
                  validation-provider(
                    name="casa",
                    rules="required",
                    v-slot="{ errors }"
                  )
                    b-form-select(
                      required,
                      name="house",
                      :options="casas",
                      v-model="profile.casa_id",
                      :state="errors[0] ? false : null",
                      :disabled="loadingCliente"
                    )
                      template(#first)
                        b-form-select-option(disabled, :value="null") {{ loadingCliente ? 'Cargando casas...' : '-- Seleccionar casa --' }}
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="12")
                b-form-group(label="Relación personal/laboral")
                  validation-provider(
                    name="relación",
                    rules="required",
                    v-slot="{ errors }"
                  )
                    b-form-select(
                      required,
                      name="relation",
                      :options="relationTypes",
                      v-model="profile.relation_type",
                      :state="errors[0] ? false : null"
                    )
                      template(#first)
                        b-form-select-option(disabled, :value="null") -- Seleccionar opción --
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="12")
                validation-provider(
                  :rules="{image: true, size: 3000}",
                  name="foto de identificación",
                  v-slot="{ errors, validate }"
                )
                  input(
                    type="file",
                    accept="image/*",
                    @change="validate(); imagePicked($event)",
                    style="display: none",
                    ref="inputPic"
                  )
                  b-form-group(
                    label="Foto de identificación",
                    v-viewer="{toolbar: false, navbar: false, movable: false, title: false, rotatable: false, scalable: false}"
                  )
                    b-img.bg-light(
                      fluid,
                      :blank="!profile.identity_img",
                      :src="profile.identity_img",
                      blank-color="#ccc",
                      style="width: 100%; height: 200px; object-fit: scale-down",
                      rounded
                    )
                    //- span.text-sm.text-danger(v-if="!profile.identity_img") El campo foto de identificación es obligatorio
                  .text-right.mt-1
                    b-button(
                      variant="soft-primary",
                      @click="$refs.inputPic.click()",
                      size="sm"
                    )
                      upload-icon.icon-sm.mr-2
                      span Subir imagen

              b-col.mb-3(cols="12", v-if="$route.params.perfil_id != 'new'")
                b-form-group(label="Status de sesión")
                  h3
                    b-badge(:variant="getStatusVariant(profile.status)", pill) {{ profile.status || 'Sin status' }}

          b-col.mb-4(cols="6")
            b-card.no-shadow
              div(v-if="profile.perfil_id")
                qr-viewer(:qrCode="profile.qr_code", :email="profile.email", type="perfil")

              b-row(v-else)
                b-col
                  .text-secondary.text-center El código QR se genera una vez que se cree el perfil

          b-col(cols="12")
            b-row
              b-col.mb-3(cols="12")
                h4 Datos del vehículo
                validation-provider(vid="hasCar")
                  b-form-checkbox(v-model="hasCar", @change="confirmRemoveCar") Registrar vehículo

              b-col.mb-3(cols="6")
                b-form-group(label="Placas")
                  validation-provider(
                    name="placas",
                    rules="required_if:hasCar",
                    v-slot="{ errors }",
                    vid="placas"
                  )
                    b-form-input(
                      placeholder="Placas",
                      required,
                      name="placas",
                      v-model="profile.placas",
                      :state="errors[0] ? false : null",
                      :disabled="!hasCar"
                    )
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="6")
                b-form-group(label="Marca del vehículo")
                  validation-provider(
                    name="marca",
                    rules="required_if:hasCar",
                    v-slot="{ errors }",
                    vid="marca"
                  )
                    b-form-input(
                      placeholder="Marca",
                      required,
                      name="marca",
                      v-model="profile.marca",
                      :state="errors[0] ? false : null",
                      :disabled="!hasCar"
                    )
                    b-form-invalid-feedback {{ errors[0] }}

              b-col.mb-3(cols="6")
                b-form-group(label="Submarca del vehículo")
                  validation-provider(
                    name="submarca",
                    rules="required_if:hasCar",
                    v-slot="{ errors }",
                    vid="submarca"
                  )
                    b-form-input(
                      placeholder="Submarca",
                      required,
                      name="submarca",
                      v-model="profile.submarca",
                      :state="errors[0] ? false : null",
                      :disabled="!hasCar"
                    )
                    b-form-invalid-feedback {{ errors[0] }}

        .text-center.text-danger.text-sm.my-3(v-if="invalid") Faltan campos por llenar o tienen error
        b-button(block, variant="primary", :disabled="invalid || loadingData", type="submit", v-if="profile.perfil_id") Guardar cambios de Perfil y QR
        b-button(block, variant="success", :disabled="invalid || loadingData", type="submit", v-else) Crear perfil
        .mt-3.text-right
          b-button.text-danger(variant="link", @click="deletePerfil") Eliminar perfil
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const perfilController = createNamespacedHelpers("perfil");
const clienteController = createNamespacedHelpers("cliente");

import { toBase64 } from "@/helpers/fileConverter";

import QRViewer from '@/components/QR.vue'

export default {
  data() {
    return {
      profile: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        relation_type: null,
        identity_img: null,
        status: null,
        placas: "",
        marca: "",
        submarca: "",
        qr_code: "",
        casa_id: null,
        cliente_id: null,
      },
      newPassword: "",
      relationTypesText: [
        "EMPLEADO DOMÉSTICO",
        "MADRE",
        "PADRE",
        "HIJO/A",
        "SOBRINO/A",
        "TÍO/A",
        "PRIMO/A",
        "AMIGO/A",
        "CHOFER",
        "OTRO",
      ],
      relationTypes: [],
      hasCar: false,
      casas: [],
      uploadingFile: false,
      clienteName: "",
      loadingCliente: false,
    };
  },
  computed: {
    ...clienteController.mapState({
      clientes: (state) => state.clientes,
    }),
    parsedClientes() {
      return Array.isArray(this.clientes)
        ? this.clientes.map((c) => {
            return {
              value: c.cliente_id,
              text: c.name,
            };
          })
        : [];
    },
  },
  methods: {
    ...mapActions([
      "perfil/get",
      "perfil/update",
      "img/create",
      "cliente/get",
      "perfil/sendQR",
      "qr/scan",
      "qr/update",
      "cliente/all",
      "perfil/create",
      "perfil/destroy"
    ]),
    getStatusVariant(status) {
      switch (status) {
        case "entrada":
          return "success";
        case "salida":
          return "secondary";
        default:
          return "light";
      }
    },
    getPerfil() {
      this.loadingData = true;
      this["perfil/get"]({
        perfil_id: this.$route.params.perfil_id,
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            this.profile = res.resource;
            this.getCliente();
            this.scanQR();
            this.hasCar =
              Boolean(this.profile.placas) ||
              Boolean(this.profile.marca) ||
              Boolean(this.profile.submarca);
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo el perfil. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    save() {
      let self = this;
      this.loadingData = true;
      let profile = {};
      let params = [
        "first_name",
        "last_name",
        "email",
        "phone_number",
        "cliente_id",
        "casa_id",
        "relation_type",
        "identity_img",
        "placas",
        "marca",
        "submarca",
      ];

      for (let param of params) {
        profile[param] = this.profile[param];
      }

      // if (!profile.identity_img) {
      //   this.$bvToast.toast(`Agrega una imagen de su identificación oficial.`, {
      //     title: "Campo obligatorio",
      //     variant: "danger",
      //   });

      //   return
      // }

      if (this.$route.params.perfil_id == 'new') {
        this["perfil/create"]({
          ...profile,
          callback: (res) => {
            this.loadingData = false;

            if (res.success) {
              this.$bvToast.toast(`Perfil creado con éxito.`, {
                title: "Creado",
                variant: "success",
              });

              setTimeout(() => {
                this.$router.push({name: 'Perfil', params: {perfil_id: res.resource.perfil_id} })
              }, 1500)
            } else {
              this.$bvToast.toast(
                `Ocurrió un error creando el perfil. Por favor intenta de nuevo.`,
                {
                  title: "Ocurrió un error",
                  variant: "danger",
                }
              );
            }
          },
        });
      } else {
        this.updateQR();
        this["perfil/update"]({
          perfil_id: this.$route.params.perfil_id,
          ...profile,
          callback: (res) => {
            this.loadingData = false;

            if (res.success) {
              this.profile = res.resource;
              this.hasCar =
                Boolean(this.profile.placas) ||
                Boolean(this.profile.marca) ||
                Boolean(this.profile.submarca);
              this.$bvToast.toast(`Perfil actualizado con éxito.`, {
                title: "Actualizado",
                variant: "success",
              });
            } else {
              this.$bvToast.toast(
                `Ocurrió un error actualizando el perfil. Por favor intenta de nuevo.`,
                {
                  title: "Ocurrió un error",
                  variant: "danger",
                }
              );
            }
          },
        });
      }
    },
    getClientes() {
      this.loadingData = true;
      this["cliente/all"]({
        params: {
          page: 1,
          limit: 500,
        },
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            // do something
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los clientes. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    getCliente() {
      this.loadingData = true;
      this.loadingCliente = true;
      this["cliente/get"]({
        cliente_id: this.profile.cliente_id,
        callback: (res) => {
          this.loadingData = false;
          this.loadingCliente = false;

          if (res.success) {
            this.clienteName = res.resource.name;
            if (
              res.resource &&
              res.resource.Casas &&
              Array.isArray(res.resource.Casas)
            ) {
              this.casas = res.resource.Casas.map((c) => {
                return {
                  value: c.casa_id,
                  text: c.name,
                };
              });
            }
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo el cliente. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    async imagePicked(event) {
      let file = event.target.files[0];
      let fileBase64 = await toBase64(file, true);

      this.$bvModal
        .msgBoxConfirm("¿Deseas reemplazar la imagen?")
        .then((value) => {
          if (value == true) {
            this.confirmUploadImage(fileBase64, event);
          } else {
            event.target.value = "";
            event.target.type = "text";
            event.target.type = "file";
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    confirmUploadImage(fileBase64, event) {
      this.uploadingFile = true;
      this["img/create"]({
        img: fileBase64,
        callback: (res) => {
          this.uploadingFile = false;
          if (res.success) {
            this.profile.identity_img = res.resource.url;
          } else {
            this.$bvToast.toast(
              `Ocurrió un error subiendo la imagen. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );

            event.target.value = "";
            event.target.type = "text";
            event.target.type = "file";
          }
        },
      });
    },
    confirmRemoveCar(value) {
      if (
        (value == false && Boolean(this.profile.placas)) ||
        Boolean(this.profile.marca) ||
        Boolean(this.profile.submarca)
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "¿Deseas eliminar los datos del vehículo? No se puede deshacer."
          )
          .then((value) => {
            if (value == true) {
              this.profile.placas = "";
              this.profile.marca = "";
              this.profile.submarca = "";
              this.hasCar = false;
            } else {
              this.hasCar = true;
            }
          })
          .catch((err) => {
            // An error occurred
          });
      }
    },
    sendQR() {
      this.$bvModal
        .msgBoxConfirm(
          `Se enviará el QR al correo ${this.profile.email}. ¿Deseas continuar?`,
          {
            okVariant: 'success',
            okTitle: 'Enviar'
          }
        )
        .then((value) => {
          if (value == true) {
            this["perfil/sendQR"]({
              perfil_id: this.$route.params.perfil_id,
              callback: (res) => {
                if (res.success) {
                  this.$bvToast.toast(
                    `Se envío el QR al correo ${this.profile.email}.`,
                    {
                      title: "Enviado",
                      variant: "success",
                    }
                  );
                } else {
                  this.$bvToast.toast(
                    `Ocurrió un error enviando QR. Por favor intenta de nuevo.`,
                    {
                      title: "Ocurrió un error",
                      variant: "danger",
                    }
                  );
                }
              },
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    scanQR() {
      this["qr/scan"]({
        qr_code: this.profile.qr_code,
        callback: (res) => {
          if (res.success) {
            let validity = res.resource.validity;
            this.qrId = res.resource.qr_id;

            if (validity) {
              if (validity.expire_at) {
                this.expirationDate = this.$moment(validity.expire_at).format(
                  "YYYY-MM-DD"
                );
              }

              if (validity.days_of_week) {
                this.restrictionDays = validity.days_of_week;
              }

              if (validity.from) {
                this.timeFrom =
                  validity.from.hour + ":" + validity.from.minute + ":" + "00";
              }

              if (validity.to) {
                this.timeTo =
                  validity.to.hour + ":" + validity.to.minute + ":" + "00";
              }
            }
          } else {
            this.$bvToast.toast(
              res.error || "Ocurrió un error obteniendo validez de QR.",
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    updateQR() {
      let validity = {
        expire_at: this.expirationDate
          ? this.$moment(this.expirationDate, "YYYY-MM-DD")
              .set({ hour: 23, minute: 59, second: 59 })
              .toISOString()
          : null,
        days_of_week: this.restrictionDays,
      };

      if (this.timeFrom && this.timeTo) {
        validity.from = {
          hour: this.timeFrom.split(":")[0],
          minute: this.timeFrom.split(":")[1],
        };

        validity.to = {
          hour: this.timeTo.split(":")[0],
          minute: this.timeTo.split(":")[1],
        };
      }

      this["qr/update"]({
        qr_id: this.qrId,
        validity,
        callback: (res) => {
          if (res.success) {
            this.$bvToast.toast(
              "Se actualizó la validez del QR correctamente.",
              {
                title: "Validez actualizada",
                variant: "success",
              }
            );
          } else {
            this.$bvToast.toast(
              res.error || "Ocurrió un error actualizando validez de QR.",
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    deletePerfil () {
      this.$bvModal
        .msgBoxConfirm("¿Deseas eliminar el perfil? No se puede deshacer.", {
          okVariant: 'danger',
          okTitle: 'Eliminar'
        })
        .then((value) => {
          if (value == true) {
            this.loadingData = true;
            this['perfil/destroy']({
              perfil_id: this.$route.params.perfil_id,
              callback: res => {
                this.loadingData = false;
                if (res.success) {
                  this.$bvToast.toast(`Perfil eliminado con éxito.`, {
                    title: 'Eliminado',
                    variant: 'success'
                  })

                  setTimeout(() => {
                    this.$router.push({name: 'Perfiles'})
                  }, 1500)
                } else {
                  this.$bvToast.toast(`Ocurrió un error eliminando el perfil. Por favor intenta de nuevo.`, {
                    title: 'Ocurrió un error',
                    variant: 'danger'
                  })
                }
              }
            })
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  mounted() {
    this.getClientes();
    for (let type of this.relationTypesText) {
      this.relationTypes.push({
        value: type,
        text: type,
      });
    }

    if (this.$route.params.perfil_id == 'new') {
      
    } else {
      this.getPerfil();
    }
  },
  watch: {
    '$route' (value) {
      if (value.params.perfil_id != 'new') {
        this.getPerfil();
      }
    }
  },
  components: {
    'qr-viewer': QRViewer
  }
};
</script>

<style lang="scss" scoped>
.qr-canvas {
  border-radius: 5px;
}
</style>